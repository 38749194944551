#classroom {

    .center {

        .header {
            padding: 24px 0;

            .sidenav-toggle {
                margin: 0;
                width: 56px;
                height: 56px;
                background: #FFFFFF;
                border-radius: 0;
                border-right: 1px solid rgba(0, 0, 0, 0.12);
            }

            .search {
                height: 56px;
                line-height: 56px;
                padding: 18px;
                background: #FFFFFF;

                .icon {
                    margin: 0;
                }

                input {
                    padding-left: 16px;
                    height: 56px;
                    color: rgba(0, 0, 0, 0.54);
                }
            }
        }

        .content-card {

        }
    }

    .main-sidenav {

        width: 650px;
        max-width: 650px;
        background-color: rgb(245,245,245) !important;

        .search {
                height: 56px;
                line-height: 56px;
                padding: 18px;
                background: #FFFFFF;

                .icon {
                    margin: 0;
                }

                input {
                    padding-left: 16px;
                    height: 56px;
                    color: rgba(0, 0, 0, 0.54);
                }
            }

        md-list {

            md-list-item {

                span {
                    font-size: 14px;
                }

                &.selected {

                    .md-button {
                        font-weight: 500;
                        color: #000000;
                    }

                }

                &.label {
                    padding: 0 8px 0 16px;

                    md-icon {
                    }

                    .label-name {
                        cursor: pointer;
                    }

                    > .md-button {
                        margin: 0;
                        padding: 0;
                        width: 32px;
                        height: 32px;
                    }

                    .editable-text {
                        width: 100%;

                        .editable-controls {
                            flex-direction: row;
                            display: flex;

                            .editable-input {
                                flex: 1;
                            }

                            .editable-buttons {

                                .md-button {
                                    margin: 0;
                                    width: 32px;
                                    padding: 0;
                                }

                            }
                        }
                    }
                }

                &.new-label-form {

                    .editable-text {
                        width: 100%;

                        .editable-controls {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;

                            .editable-input {
                                border: 1px solid rgba(0, 0, 0, 0.2);
                                padding: 4px 2px;
                                margin-bottom: 8px;
                                font-size: 13px;
                                width: 100%;
                            }

                            .editable-buttons {

                                .md-button {
                                    margin: 0;
                                    margin: 0;
                                    padding: 0;
                                    height: 32px;
                                    min-height: 32px;
                                    line-height: 32px;
                                    width: 32px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}