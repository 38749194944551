#contractGroupCodebook {

    .search {
        height: 56px;
        line-height: 56px;
        padding: 18px;
        background: #FFFFFF;

        .icon {
            margin: 0;
        }

        input {
            padding-left: 16px;
            height: 56px;
            color: rgba(0, 0, 0, 0.54);
        }
    }

    .thread {
        background: #FAFAFA;
        position: relative;
        padding: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        &.unread {
            background: #FFFFFF;

            .info {

                .name,
                .subject {
                    font-weight: 700;
                }

                .message {

                    .labels {
                        background: #FFFFFF;
                    }
                }
            }

        }

        &.selected {
            background: #FFF8E1;

            .info {

                .message {

                    .labels {
                        background: #FFF8E1;
                    }
                }
            }
        }

        &.current-thread {
            background: #E3F2FD;

            .info {

                .message {

                    .labels {
                        background: #E3F2FD;
                    }
                }
            }
        }

        .info {
            overflow: hidden;
            width: 0;
            margin: 0 16px;

            .name {
                font-size: 15px;
                font-weight: 500;
                padding-bottom: 8px;

                .avatar {
                    min-width: 32px;
                    width: 32px;
                    height: 32px;
                    line-height: 32px;
                }

                .has-attachment {
                    margin-left: 8px;
                    transform: rotate(90deg);
                }
            }

            .subject {

            }

            .message {
                position: relative;
                color: rgba(0, 0, 0, 0.54);

                .labels {
                    position: absolute;
                    background: #FAFAFA;
                    top: 0;
                    right: 0;
                    padding-left: 6px;

                    .label {
                        display: inline-block;
                        font-size: 11px;
                        padding: 0 5px;
                        margin-left: 6px;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }

        .time {
            margin: 0 8px 6px 8px;
        }
    }
}