$quickPanelWidth: 550px;
$quickPanelMobileWidth: 80%;

#quick-panel {
    width: $quickPanelWidth;
    min-width: $quickPanelWidth;
    max-width: $quickPanelWidth;
    z-index: 99;

    md-content {
        height: 100%;

        md-tabs {
            height: 100%;

            md-tabs-wrapper {
                background-color: rgba(0, 0, 0, 0.03);
                border-bottom: 1px solid rgba(0, 0, 0, 0.06);

                .md-tab {
                    max-width: none !important;
                }
            }

            md-tabs-content-wrapper {

                md-tab-content {
                    height: 100%;
                    overflow: hidden;

                    > div {
                        overflow: hidden;
                        height: 100%;
                    }

                    md-list {

                        .md-subheader {

                        }

                        md-list-item {
                        }
                    }
                }
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #quick-panel {
        width: $quickPanelMobileWidth;
        min-width: $quickPanelMobileWidth;
        max-width: $quickPanelMobileWidth;
    }
}