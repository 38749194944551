.divMessageBox {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 100000
}

.MessageBoxContainer {
    top: 35%;
    color: #fff;
    position: relative;
    width: 100%;
    background-color: #232323;
    background-color: rgba(0, 0, 0, .8);
    padding: 20px
}

.MessageBoxMiddle {
    position: relative;
    left: 20%;
    width: 60%
}

.MessageBoxMiddle .MsgTitle {
    letter-spacing: -1px;
    font-size: 24px;
    font-weight: 300
}

.MessageBoxMiddle .pText {
    font-size: 13px
}

.MessageBoxContainer input,
.MessageBoxContainer select {
    width: 50%;
    padding: 5px
}

.MessageBoxButtonSection {
    width: 100%;
    height: 30px
}

.MessageBoxButtonSection button {
    float: right;
    margin-right: 7px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    font-weight: 700
}

.LoadingBoxContainer {
    top: 20%;
    color: #fff;
    position: relative;
    width: 100%;
    background-color: #232323
}

.LoadingBoxMiddle {
    position: relative;
    left: 20%;
    width: 50%;
    padding: 10px
}

.LoadingBoxContainer .MsgTitle {
    font-size: 26px
}

.LoadingBoxContainer .pText {
    font-size: 13px
}

#LoadingPoints {
    position: absolute
}

.bigBox {
    position: fixed;
    right: 10px;
    bottom: 10px;
    background-color: #004d60;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    width: 390px;
    height: 150px;
    color: #fff;
    z-index: 99999;
    box-sizing: content-box;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    border-left: 5px solid rgba(0, 0, 0, .15);
    overflow: hidden
}

.bigBox span {
    font-size: 17px;
    font-weight: 300;
    letter-spacing: -1px;
    padding: 5px 0!important;
    display: block
}

.bigBox p {
    font-size: 13px;
    margin-top: 10px
}

#divMiniIcons {
    position: fixed;
    width: 415px;
    right: 10px;
    bottom: 180px;
    z-index: 9999;
    float: right
}

.botClose {
    position: absolute;
    right: 10px;
    height: 16px;
    width: 15px;
    cursor: pointer;
    font-size: 18px;
    opacity: .5;
    display: block;
    top: 15px
}

.botClose:hover {
    opacity: 1
}

#divMiniIcons .cajita {
    text-align: center;
    vertical-align: middle;
    padding: 4px 6px;
    color: #FFF;
    float: right;
    cursor: pointer;
    display: block;
    background-color: red;
    font-size: 17px;
    margin-left: 4px;
    margin-top: 5px
}

#divMiniIcons .cajita:active {
    top: 1px;
    left: 1px;
    position: relative
}

.cajita img {
    width: 23px;
    height: 23px;
    padding-left: 3px;
    padding-top: 3px
}

.bigBox .bigboxicon {
    font-size: 30px;
    text-align: left;
    position: absolute;
    top: 120px;
    left: 6px;
    z-index: 0
}

.bigBox .bigboxnumber {
    width: 100%;
    text-align: right;
    font-size: 25px
}

#divSmallBoxes {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999
}

.SmallBox {
    position: absolute;
    right: 5px;
    top: 20px;
    width: 420px;
    color: #fff;
    z-index: 9999;
    overflow: hidden;
    border: 1px solid transparent
}

.SmallBox:hover {
    border: 1px solid #fff;
    cursor: pointer
}

.SmallBox .foto {
    font-size: 50px;
    position: absolute;
    left: 17px
}

.SmallBox .textoFull {
    width: 93%;
    float: left;
    padding-left: 20px
}

.SmallBox .textoFoto {
    width: 78%;
    margin: 3px 20px 3px 80px;
    float: left
}

.miniPic {
    position: absolute;
    bottom: 8px;
    right: 9px
}

.miniIcono {
    height: 100%;
    font-size: 20px
}

.SmallBox span {
    font-size: 17px;
    font-weight: 300;
    letter-spacing: -1px;
    display: block;
    margin: 4px 0
}

.SmallBox p {
    font-size: 13px;
    margin-top: 2px
}

#pageslide {
    display: none;
    position: absolute;
    position: fixed;
    top: 0;
    height: 100%;
    z-index: 999999;
    width: 305px;
    padding: 20px;
    background-color: #004d60;
    color: #FFF;
    -webkit-box-shadow: inset 0 0 5px 5px #222;
    -moz-shadow: inset 0 0 0 0 #222;
    box-shadow: inset 0 0 0 0 #222
}

.purehtml {
    color: #fff;
    font-size: 16px
}

@media screen and (max-width:450px) and (max-width:767px) {
    #divSmallBoxes {
        position: fixed;
        width: 90%;
        right: 0;
        top: 0
    }
    .SmallBox {
        width: 95%
    }
    .SmallBox:hover {
        box-shadow: 0 0 10px #888;
        cursor: pointer
    }
    .SmallBox span {
        font-size: 16px
    }
    .SmallBox p {
        font-size: 12px;
        margin-top: 2px
    }
    .SmallBox .textoFull {
        width: 93%;
        float: left;
        padding-left: 20px
    }
    .SmallBox .textoFoto {
        width: 55%;
        margin: 3px 20px 3px 80px;
        float: left
    }
    .bigBox {
        position: fixed;
        right: 10px;
        bottom: 10px;
        background-color: #004d60;
        padding-left: 10px;
        padding-top: 10px;
        padding-right: 10px;
        padding-bottom: 5px;
        width: 88%;
        height: 150px;
        color: #fff;
        z-index: 99999
    }
    .divMessageBox {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, .6);
        z-index: 100000
    }
    .MessageBoxContainer {
        top: 25%;
        color: #fff;
        position: relative;
        width: 100%;
        background-color: #232323
    }
    .MessageBoxMiddle {
        position: relative;
        left: 0;
        width: 100%;
        padding: 3px
    }
    .MessageBoxMiddle .MsgTitle {
        font-size: 22px
    }
    .MessageBoxMiddle .pText {
        font-size: 13px
    }
    .MessageBoxContainer input,
    .MessageBoxContainer select {
        width: 50%;
        padding: 5px
    }
    .MessageBoxButtonSection {
        width: 100%;
        height: 30px
    }
    .MessageBoxButtonSection button {
        float: right;
        margin-right: 5px;
        padding-left: 15px;
        padding-right: 15px
    }
}

.animated {
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both
}

.animated.fast {
    -webkit-animation-duration: .4s;
    -moz-animation-duration: .4s;
    -ms-animation-duration: .4s;
    -o-animation-duration: .4s;
    animation-duration: .4s
}

.jqstooltip {
    padding: 7px!important;
    border-radius: 3px!important;
    -webkit-border-radius: 3px!important;
    -moz-border-radius: 3px!important;
    border: none!important;
    background-color: #000!important;
    box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box
}

.jqstooltip .jqsfield {
    font-family: "Segoe UI", "Open Sans", Calibri, Candara, Arial, sans-serif;
    font-size: 11px
}

#sparks {
    display: block;
    list-style: none;
    margin: 10px 0 0;
    padding: 0;
    text-align: right
}

#sparks li {
    display: inline-block;
    max-height: 47px;
    overflow: hidden;
    text-align: left;
    box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box
}

#sparks li h5 {
    color: #555;
    float: left;
    font-size: 11px;
    font-weight: 400;
    margin: -3px 0 0 0;
    padding: 0;
    border: none;
    text-transform: uppercase
}

#sparks li span {
    color: #636363;
    display: block
}

#sparks li {
    border-left: 1px dotted #c7c7c7;
    padding: 0 10px
}

#sparks li:last-child {
    padding-right: 0
}

#sparks li:first-child {
    border-left: none;
    padding-left: 0
}

.sparks-info {
    min-width: 70px
}

.sparks-info span {
    font-size: 18px;
    line-height: 20px;
    margin: 0;
    text-transform: none
}

.sparks-info .sparkline {
    display: block;
    float: right;
    margin: 3px 0 0 20px
}

[data-sparkline-width="100%"]>canvas {
    width: 100%!important
}

.dataTable [data-sparkline-width="100%"]>canvas {
    max-width: 60px!important
}

.note-editor {
    border: 1px solid #a9a9a9;
    position: relative
}

.note-editor .note-dropzone {
    position: absolute;
    display: none;
    z-index: 1;
    border: 2px dashed #87cefa;
    color: #87cefa;
    background-color: #fff;
    opacity: .95;
}

.note-editor .note-dropzone .note-dropzone-message {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: 28px;
    font-weight: 700
}

.note-editor .note-dropzone.hover {
    border: 2px dashed #098ddf;
    color: #098ddf
}

.note-editor.dragover .note-dropzone {
    display: table
}

.note-editor .note-toolbar {
    background-color: #f5f5f5;
    border-bottom: 1px solid #a9a9a9
}

.note-editor.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1050
}

.note-editor.fullscreen .note-editable {
    background-color: #fff
}

.note-editor.codeview .note-editable,
.note-editor.fullscreen .note-resizebar {
    display: none
}

.note-editor.codeview .note-codable {
    display: block
}

.note-editor .note-statusbar {
    background-color: #f5f5f5
}

.note-editor .note-statusbar .note-resizebar {
    height: 8px;
    width: 100%;
    cursor: ns-resize
}

.note-editor .note-statusbar .note-resizebar .note-icon-bar {
    width: 20px;
    margin: 1px auto;
    border-top: 1px solid #a9a9a9
}

.note-editor .note-editable {
    padding: 10px;
    overflow: auto;
    outline: 0
}

.note-editor .note-editable[contenteditable=false] {
    background-color: #e5e5e5
}

.note-editor .note-codable {
    display: none;
    width: 100%;
    padding: 10px;
    border: none;
    box-shadow: none;
    font-family: Menlo, Monaco, monospace, sans-serif;
    font-size: 14px;
    color: #ccc;
    background-color: #222;
    resize: none;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin-bottom: 0
}

.note-air-editor {
    outline: 0
}

.note-popover .popover {
    max-width: none
}

.note-popover .popover .popover-content a {
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle
}

.note-popover .popover .arrow {
    left: 20px
}

.note-popover .popover .popover-content,
.note-toolbar {
    margin: 0;
    padding: 0 0 5px 5px
}

.note-popover .popover .popover-content>.btn-group,
.note-toolbar>.btn-group {
    margin-top: 5px;
    margin-left: 0;
    margin-right: 5px
}

.note-popover .popover .popover-content .note-table .dropdown-menu,
.note-toolbar .note-table .dropdown-menu {
    min-width: 0;
    padding: 5px
}

.note-popover .popover .popover-content .note-table .dropdown-menu .note-dimension-picker,
.note-toolbar .note-table .dropdown-menu .note-dimension-picker {
    font-size: 18px
}

.note-popover .popover .popover-content .note-table .dropdown-menu .note-dimension-picker .note-dimension-picker-mousecatcher,
.note-toolbar .note-table .dropdown-menu .note-dimension-picker .note-dimension-picker-mousecatcher {
    position: absolute!important;
    z-index: 3;
    width: 10em;
    height: 10em;
    cursor: pointer
}

.note-popover .popover .popover-content .note-table .dropdown-menu .note-dimension-picker .note-dimension-picker-unhighlighted,
.note-toolbar .note-table .dropdown-menu .note-dimension-picker .note-dimension-picker-unhighlighted {
    position: relative!important;
    z-index: 1;
    width: 5em;
    height: 5em;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC) repeat
}

.note-popover .popover .popover-content .note-table .dropdown-menu .note-dimension-picker .note-dimension-picker-highlighted,
.note-toolbar .note-table .dropdown-menu .note-dimension-picker .note-dimension-picker-highlighted {
    position: absolute!important;
    z-index: 2;
    width: 1em;
    height: 1em;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIjd6vvD2f9LKLW+AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKwNDEVT0AAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC) repeat
}

.note-popover .popover .popover-content .note-style blockquote,
.note-popover .popover .popover-content .note-style h1,
.note-popover .popover .popover-content .note-style h2,
.note-popover .popover .popover-content .note-style h3,
.note-popover .popover .popover-content .note-style h4,
.note-popover .popover .popover-content .note-style h5,
.note-popover .popover .popover-content .note-style h6,
.note-toolbar .note-style blockquote,
.note-toolbar .note-style h1,
.note-toolbar .note-style h2,
.note-toolbar .note-style h3,
.note-toolbar .note-style h4,
.note-toolbar .note-style h5,
.note-toolbar .note-style h6 {
    margin: 0
}

.note-popover .popover .popover-content .note-color .dropdown-toggle,
.note-toolbar .note-color .dropdown-toggle {
    width: 20px;
    padding-left: 5px
}

.note-popover .popover .popover-content .note-color .dropdown-menu,
.note-toolbar .note-color .dropdown-menu {
    min-width: 340px
}

.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group,
.note-toolbar .note-color .dropdown-menu .btn-group {
    margin: 0
}

.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group:first-child,
.note-toolbar .note-color .dropdown-menu .btn-group:first-child {
    margin: 0 5px
}

.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group .note-palette-title,
.note-toolbar .note-color .dropdown-menu .btn-group .note-palette-title {
    font-size: 12px;
    margin: 2px 7px;
    text-align: center;
    border-bottom: 1px solid #eee
}

.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group .note-color-reset,
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset {
    font-size: 11px;
    margin: 3px;
    padding: 0 3px;
    cursor: pointer;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px
}

.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group .note-color-row,
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-row {
    height: 20px
}

.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group .note-color-reset:hover,
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset:hover {
    background: #eee
}

.note-popover .popover .popover-content .note-para .dropdown-menu,
.note-toolbar .note-para .dropdown-menu {
    min-width: 216px;
    padding: 5px
}

.note-popover .popover .popover-content .note-para .dropdown-menu>div:first-child,
.note-toolbar .note-para .dropdown-menu>div:first-child {
    margin-right: 5px
}

.note-popover .popover .popover-content .dropdown-menu,
.note-toolbar .dropdown-menu {
    min-width: 90px
}

.note-popover .popover .popover-content .dropdown-menu.right,
.note-toolbar .dropdown-menu.right {
    right: 0;
    left: auto
}

.note-popover .popover .popover-content .dropdown-menu.right::before,
.note-toolbar .dropdown-menu.right::before {
    right: 9px;
    left: auto!important
}

.note-popover .popover .popover-content .dropdown-menu.right::after,
.note-toolbar .dropdown-menu.right::after {
    right: 10px;
    left: auto!important
}

.note-popover .popover .popover-content .dropdown-menu li a i,
.note-toolbar .dropdown-menu li a i {
    color: #00bfff;
    visibility: hidden
}

.note-popover .popover .popover-content .dropdown-menu li a.checked i,
.note-toolbar .dropdown-menu li a.checked i {
    visibility: visible
}

.note-popover .popover .popover-content .note-fontsize-10,
.note-toolbar .note-fontsize-10 {
    font-size: 10px
}

.note-popover .popover .popover-content .note-color-palette,
.note-toolbar .note-color-palette {
    line-height: 1
}

.note-popover .popover .popover-content .note-color-palette div .note-color-btn,
.note-toolbar .note-color-palette div .note-color-btn {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    border: 1px solid #fff
}

.note-popover .popover .popover-content .note-color-palette div .note-color-btn:hover,
.note-toolbar .note-color-palette div .note-color-btn:hover {
    border: 1px solid #000
}

.note-dialog>div {
    display: none
}

.note-dialog .note-image-dialog .note-dropzone {
    min-height: 100px;
    font-size: 30px;
    line-height: 4;
    color: #d3d3d3;
    text-align: center;
    border: 4px dashed #d3d3d3;
    margin-bottom: 10px
}

.note-dialog .note-help-dialog {
    font-size: 12px;
    color: #ccc;
    background-color: #222!important;
    -webkit-opacity: .9;
    -khtml-opacity: .9;
    -moz-opacity: .9;
    opacity: .9;
    -ms-filter: alpha(opacity=90);
    filter: alpha(opacity=90);
    background: 0 0;
    border: none
}

.note-dialog .note-help-dialog .modal-content {
    background: 0 0;
    border: 1px solid #fff;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px
}

.note-dialog .note-help-dialog a {
    font-size: 12px;
    color: #fff
}

.note-dialog .note-help-dialog .title {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 5px;
    border-bottom: #fff 1px solid
}

.note-dialog .note-help-dialog .modal-close {
    font-size: 14px;
    color: #dd0;
    cursor: pointer
}

.note-dialog .note-help-dialog .note-shortcut-layout {
    width: 100%
}

.note-dialog .note-help-dialog .note-shortcut-layout td {
    vertical-align: top
}

.note-dialog .note-help-dialog .note-shortcut {
    margin-top: 8px
}

.note-dialog .note-help-dialog .note-shortcut th {
    text-align: left;
    font-size: 13px;
    color: #dd0
}

.note-dialog .note-help-dialog .note-shortcut td:first-child {
    min-width: 110px;
    font-family: "Courier New";
    color: #dd0;
    text-align: right;
    padding-right: 10px
}

.note-handle .note-control-selection {
    position: absolute;
    display: none;
    border: 1px solid #000
}

.note-handle .note-control-selection>div {
    position: absolute
}

.note-handle .note-control-selection .note-control-selection-bg {
    width: 100%;
    height: 100%;
    background-color: #000;
    -webkit-opacity: .3;
    -khtml-opacity: .3;
    -moz-opacity: .3;
    opacity: .3;
    -ms-filter: alpha(opacity=30);
    filter: alpha(opacity=30)
}

.note-handle .note-control-selection .note-control-handle,
.note-handle .note-control-selection .note-control-holder {
    width: 7px;
    height: 7px;
    border: 1px solid #000
}

.note-handle .note-control-selection .note-control-sizing {
    width: 7px;
    height: 7px;
    border: 1px solid #000;
    background-color: #fff
}

.note-handle .note-control-selection .note-control-nw {
    top: -5px;
    left: -5px;
    border-right: none;
    border-bottom: none
}

.note-handle .note-control-selection .note-control-ne {
    top: -5px;
    right: -5px;
    border-bottom: none;
    border-left: none
}

.note-handle .note-control-selection .note-control-sw {
    bottom: -5px;
    left: -5px;
    border-top: none;
    border-right: none
}

.note-handle .note-control-selection .note-control-se {
    right: -5px;
    bottom: -5px;
    cursor: se-resize
}

.note-handle .note-control-selection .note-control-selection-info {
    right: 0;
    bottom: 0;
    padding: 5px;
    margin: 5px;
    color: #fff;
    background-color: #000;
    font-size: 12px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-opacity: .7;
    -khtml-opacity: .7;
    -moz-opacity: .7;
    opacity: .7;
    -ms-filter: alpha(opacity=70);
    filter: alpha(opacity=70)
}

.no-padding .note-editor {
    border: none
}

.note-editor .note-editable {
    background-color: rgba(48, 126, 204, .05)
}

.note-editor .note-editable:focus {
    background-color: #fff
}

.note-editor .note-statusbar .note-resizebar {
    border-top: 1px solid #DBDBDB
}

.note-editor .note-statusbar:hover {
    background: #EEE
}

.note-editor .note-statusbar:active {
    background: #eaeaea
}

.superbox *,
.superbox:after,
.superbox:before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility
}

.superbox-list {
    display: inline-block;
    *display: inline;
    zoom: 1;
    width: 12.5%;
    padding: 5px;
    position: relative
}

.superbox-list.active:before {
    content: "\f0d8";
    font-family: FontAwesome;
    display: block;
    position: absolute;
    left: 43%;
    bottom: -34px;
    z-index: 2;
    text-align: center;
    font-size: 45px;
    color: #222
}

.superbox-img {
    max-width: 100%;
    width: 100%;
    cursor: pointer
}

.superbox-show {
    text-align: left;
    position: relative;
    background-color: #222;
    width: 100%;
    float: left;
    padding: 25px 0 12px 25px;
    display: none;
    margin: 5px 0
}

.superbox-current-img {
    max-width: 100%;
    float: left;
    padding-right: 25px;
    padding-bottom: 13px
}

.superbox-img:hover {
    opacity: .8
}

.superbox-float {
    float: left
}

.superbox-close {
    opacity: .7;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    transition: all .1s linear 0s
}

.superbox-close:hover {
    opacity: 1
}

.superbox-imageinfo {
    display: inline-block;
    max-width: 500px;
    color: #fff;
    padding: 0 25px 0 0
}

.superbox-imageinfo span {
    font-size: 13px;
    color: #a19994;
    margin-right: -2px;
    padding-top: 10px;
    display: inline-block;
    padding-bottom: 13px
}

.superbox-imageinfo>:first-child {
    margin-bottom: 10px;
    font-weight: 700
}

.superbox-imageinfo span p:last-child {
    margin-top: 30px
}

@media only screen and (min-width:320px) {
    .superbox-list {
        width: 50%
    }
}

@media only screen and (min-width:486px) {
    .superbox-list {
        width: 25%
    }
}

@media only screen and (min-width:768px) {
    .superbox-list {
        width: 16.66666667%
    }
}

@media only screen and (min-width:1025px) {
    .superbox-list {
        width: 12.5%
    }
}

@media only screen and (min-width:1824px) {
    .superbox-list {
        width: 12.5%
    }
}

#speech-btn>:first-child {
    position: relative
}

.voice-command-active #speech-btn>:first-child>a {
    background: #0E70CA;
    border: 1px solid #125A9C;
    color: rgba(255, 255, 255, .9)
}

.voice-command-active.service-not-allowed #speech-btn>:first-child>a {
    background: #a90329;
    border: 1px solid #8B0913;
    color: rgba(255, 255, 255, .9)
}

.voice-command-active.service-allowed #speech-btn>:first-child>a {
    background: url(../img/voicecommand/active-btn.gif) no-repeat center center #0e70ca;
    border: 1px solid #125A9C;
    color: rgba(255, 255, 255, .9)
}

#speech-btn>:first-child>a+.popover {
    display: none;
    top: 42px;
    left: -113px;
    width: 255px
}

#speech-btn>:first-child>a+.popover>.popover-content {
    padding: 9px 12px
}

#speech-btn>:first-child>a+.popover .btn {
    padding: 4px 12px
}

.vc-title {
    margin-bottom: 10px!important;
    font-size: 16px;
    line-height: 20px;
    text-align: center
}

.vc-title-error {
    display: none
}

.service-not-allowed .popover .btn,
.service-not-allowed .vc-title,
.voice-command-active.service-not-allowed .popover .btn,
.voice-command-active.service-not-allowed .vc-title {
    display: none!important
}

.voice-command-active.service-not-allowed .vc-title-error {
    display: block
}

.vc-title>small {
    font-size: 80%
}