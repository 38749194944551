@import '../../school/school';

#classroom-teacher {
    height: 100%;

    > .header {
        position: relative;
        height: 96px;
        min-height: 96px;
        max-height: 96px;
        background-image: none;
        z-index: 49;

        .header-content {

            .header-boards-button {
                margin: 0;

                .arrow {
                    transition: transform 0.3s ease-in-out, opacity 0.25s ease-in-out 0.1s;
                }
            }

            .header-board-name {
                font-size: 16px;

                .board-subscribe {
                    margin-right: 8px;
                }

                .editable-buttons {

                    md-icon {
                        color: #FFFFFF !important;
                    }
                }
            }

            .right-side {

                > .md-button:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    #board-selector {
        position: absolute;
        top: 96px;
        right: 0;
        left: 0;
        height: 252px;
        z-index: 48;
        padding: 24px;
        opacity: 1;

        md-input-container.md-default-theme:not(.md-input-invalid).md-input-has-value label, md-input-container:not(.md-input-invalid).md-input-has-value label {
            color: rgb(250,250,250);
        }

        md-input-container.md-default-theme .md-input, md-input-container .md-input {
            color: rgb(250,250,250);
        }

        input[kendo-date-picker] {
            color: rgba(0,0,0,0.87) !important;
        } 

        .md-chips .md-chip {
            background-color: transparent;
            color: rgb(250,250,250);
            border-color: rgb(250,250,250);

            md-icon.md-default-theme, md-icon {
                color: rgb(250,250,250);
            }

            input::-webkit-input-placeholder { 
                color: rgb(250,250,250);
            }
            input:-moz-placeholder { 
                color: rgb(250,250,250);
            }
            input::-moz-placeholder { 
                color: rgb(250,250,250);
            }
            input:-ms-input-placeholder { 
                color: rgb(250,250,250);
            }
        }

        .list-sortable-placeholder {
            background: rgba(0, 0, 0, 0.06);
            margin-right: 24px;
        }

        .board-list {
            &.ui-sortable-helper .board-list-item {
                box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
            }
        }

        .board-list-item {
            width: 128px;
            height: 192px;
            padding: 16px;
            cursor: pointer;
            position: relative;

            .board-name {
                text-align: center;
                padding: 16px 0;
            }

            .selected-icon {
                position: absolute;
                top: 0;
                left: 50%;
                width: 32px;
                height: 32px;
                margin-left: -16px;
                border-radius: 50%;
                text-align: center;
                color: white;

                i {
                    line-height: 32px !important;
                }
            }

            &.add-new-board {
                opacity: 0.6;
            }
        }
    }

    .content {
        padding: 0;
        background: transparent;
    }

    .editable-click {
        cursor: pointer;
        text-decoration: none;
        color: inherit;
        border-bottom: none;
    }

    .editable-wrap {
        position: relative;

        .editable-controls {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            .editable-input {
                width: inherit;
                background-color: white;
                padding: 8px;
                border: 1px solid rgba(0, 0, 0, 0.12);
            }

            .editable-buttons {
                display: inherit;

                .md-button {
                    margin: 0;

                    &:first-of-type {
                        padding-right: 0;
                    }

                    .icon-cancel {
                        color: rgba(0, 0, 0, 0.32);
                    }
                }
            }
        }
    }

    .board-selector-backdrop {
        z-index: 47;
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #classroom-teacher {

        .header {
            height: 120px;
            max-height: 120px;
            min-height: 120px;

        }

        #board-selector {
            top: 120px;
        }
    }
}

#classroom-board {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    background: #E5E7E8;
    padding: 24px;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    .list-wrapper {
        width: 644px;
        min-width: 644px;
        max-width: 644px;
        padding-right: 24px;
        height: 100%;

        &.ui-sortable-helper .list {
            box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        }

        .list {
            max-height: 100%;
            background-color: #EEF0F2;
            color: #000;
            border-radius: 2px;
            transition: box-shadow 150ms ease;

            .list-header {
                height: 64px;
                min-height: 64px;
                padding: 0 0 0 16px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);

                .list-header-name {
                    max-width: 80%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-size: 15px;
                    font-weight: 500;
                }

                .list-header-option-button {

                    .md-button {
                    }
                }
            }

            .list-content {
                position: relative;
                overflow: hidden;
                overflow-y: auto;
                min-height: 0;

                .list-cards {
                    position: relative;
                    min-height: 32px;
                    padding: 0 16px;

                    .card-sortable-placeholder {
                        background: rgba(0, 0, 0, 0.06);
                    }

                    > div {
                        transition: none !important;
                    }
                }
            }

            .list-footer {
                display: flex;
                flex-direction: column;
                flex: 1 0 auto;
                min-height: 48px;
            }

        }
    }

    .list-sortable-placeholder {
        background: rgba(0, 0, 0, 0.06);
        margin-right: 24px;
    }

    .new-list-wrapper {
        width: 344px;
        min-width: 344px;
        max-width: 344px;
        padding-right: 24px;

        .new-list {
            border-radius: 2px;
            background-color: #EEF0F2;

            .editable-wrap {
                display: flex;
                align-items: center;
                height: 64px;
                padding: 0 4px 0 12px;

                .editable-controls {
                    width: 100%;

                    .editable-input {
                        flex: 1;
                    }
                }
            }

            .new-list-form-button {
                text-transform: none;
                font-size: 15px;
                height: 64px;
                padding: 0 16px;
                margin: 0;
                width: 100%;

                i {
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    line-height: 40px;
                    margin-right: 16px;
                }
            }
        }
    }

    .list-card {
        @include list-card;
    }
}