#classroom-teacher {

    .center {

        .content-card {

            &.outlook {

                .toolbar {
                    position: relative;
                    padding: 4px;

                    .search {
                        height: 56px;
                        line-height: 56px;
                        padding: 18px;
                        background: #FFFFFF;

                        .icon {
                            margin: 0;
                        }

                        input {
                            padding-left: 16px;
                            height: 56px;
                            color: rgba(0, 0, 0, 0.54);
                        }
                    }

                    .select-menu {

                        .md-icon-button {
                            padding-left: 0;
                            margin: 0;
                        }
                    }

                    .page-info {
                        font-weight: 500;
                        margin: 0 8px;
                        white-space: nowrap;
                    }

                    .close-thread {
                        margin: 0 0 0 -8px;
                    }
                }

                .content-wrapper {
                    position: relative;
                }
            }
        }
    }
}

.thread-labels-toggle-list {

    md-list-item {

        md-icon {
            margin: 0 !important;
        }
    }
}