#subject-search {
    .search {
        height: 56px;
        line-height: 56px;
        padding: 18px;
        background: #FFFFFF;

        .icon {
            margin: 0;
        }

        input {
            padding-left: 16px;
            height: 56px;
            color: rgba(0, 0, 0, 0.54);
        }
    }
}

#classroom-teacher {

    .center {

        .content-card {

            &.outlook {

                .content-wrapper {

                    .subject-list {
                        background: #FAFAFA;
                        position: relative;
                        padding: 0;
                        border-right: 1px solid rgba(0, 0, 0, 0.12);

                        .loading-container {
                            display: block;
                            position: relative;
                            width: 100%;
                            height: 100%;
                            border: 2px solid #aad1f9;
                            transition: opacity  0.1s linear;
                            border-top: 0px; 
                            margin: 8px;
                        }

                        .loading-bottom-block {
                            display: block;
                            position: relative;
                            background-color: rgba(255, 235, 169, 0.25);
                            height: 100%;
                            width: 100%; 
                        }

                        .no-threads {
                            background: #FAFAFA;
                            width: 100%;
                            text-align: center;
                            padding: 32px;
                            font-size: 20px;
                        }

                        .loading-threads {
                            background: #FAFAFA;
                            width: 100%;
                            text-align: center;
                            padding: 32px;
                            font-size: 20px;
                        }

                        .first {
                            border-top: 1px solid rgba(0, 0, 0, 0.12);
                        }

                        .last {
                            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                        }

                        .thread {
                            background: #FAFAFA;
                            position: relative;
                            padding: 16px;
                            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                            cursor: pointer;

                            &.unread {
                                background: #FFFFFF;

                                .info {

                                    .name,
                                    .subject {
                                        font-weight: 700;
                                    }

                                    .message {

                                        .labels {
                                            background: #FFFFFF;
                                        }
                                    }
                                }

                            }

                            &.selected {
                                background: #FFF8E1;

                                .info {

                                    .message {

                                        .labels {
                                            background: #FFF8E1;
                                        }
                                    }
                                }
                            }

                            &.current-thread {
                                background: #E3F2FD;

                                .info {

                                    .message {

                                        .labels {
                                            background: #E3F2FD;
                                        }
                                    }
                                }
                            }

                            .info {
                                overflow: hidden;
                                width: 0;
                                margin: 0 16px;

                                .name {
                                    font-size: 15px;
                                    font-weight: 500;
                                    padding-bottom: 8px;

                                    .avatar {
                                        min-width: 32px;
                                        width: 32px;
                                        height: 32px;
                                        line-height: 32px;
                                    }

                                    .has-attachment {
                                        margin-left: 8px;
                                        transform: rotate(90deg);
                                    }
                                }

                                .subject {
                                }

                                .message {
                                    position: relative;
                                    color: rgba(0, 0, 0, 0.54);

                                    .labels {
                                        position: absolute;
                                        background: #FAFAFA;
                                        top: 0;
                                        right: 0;
                                        padding-left: 6px;

                                        .label {
                                            display: inline-block;
                                            font-size: 11px;
                                            padding: 0 5px;
                                            margin-left: 6px;

                                            &:first-child {
                                                margin-left: 0;
                                            }
                                        }
                                    }
                                }
                            }

                            .time {
                                margin: 0 8px 6px 8px;
                            }
                        }

                        .thread-detail {
                            background: #FFFFFF;
                            padding: 12px;

                            .no-stage-selected {

                                md-icon {
                                    margin: 0 0 12px 0;
                                }

                                span {
                                    font-size: 24px;
                                    font-weight: 300;
                                }
                            }

                            .no-thread-selected {

                                md-icon {
                                    margin: -56px 0 12px 0;
                                }

                                span {
                                    font-size: 24px;
                                    font-weight: 300;
                                }
                            }

                            .thread-header {
                                padding-bottom: 12px;
                                border-bottom: 1px solid rgba(0, 0, 0, 0.12);

                                .actions {
                                    min-width: 88px;

                                    .md-icon-button {
                                        padding: 0;
                                        width: 32px;
                                        height: 32px;
                                    }
                                }

                                .subject {
                                    font-size: 17px;
                                    font-weight: 500;
                                }

                                .label {
                                    display: inline-block;
                                    font-size: 11px;
                                    padding: 0 5px;
                                    margin: 8px 6px 0 0;
                                }
                            }

                            .thread-content {
                                // padding: 24px 0;

                                .to {
                                    color: rgba(0, 0, 0, 0.54);

                                    .to-text {
                                        margin-right: 4px;
                                        text-transform: lowercase;
                                    }
                                }

                                .info {
                                    padding-bottom: 16px;

                                    .avatar {
                                        margin-right: 16px;
                                    }

                                    .name {
                                        margin-right: 8px;
                                        font-weight: 500;
                                    }

                                    .toggle-details {
                                        padding-top: 16px;
                                        cursor: pointer;
                                        font-weight: 500;
                                    }

                                    .details {
                                        padding-top: 8px;

                                        .title {
                                            font-weight: 500;
                                            margin-right: 6px;
                                        }

                                        .detail {
                                            color: rgba(0, 0, 0, 0.54);
                                        }
                                    }

                                }
                            }

                            .thread-attachments {
                                padding-bottom: 24px;
                                border-top: 1px solid rgba(0, 0, 0, 0.12);

                                .title {
                                    margin-bottom: 16px;
                                    font-weight: 500;
                                }

                                .attachment {

                                    .preview {
                                        width: 100px;
                                        margin: 0 16px 8px 0;
                                    }

                                    .link {
                                        margin-bottom: 2px;
                                    }

                                    .size {
                                        font-size: 11px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}