
@import '../../../school';

#board {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    background: #E5E7E8;
    padding: 24px;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    .list-wrapper {
        width: 344px;
        min-width: 344px;
        max-width: 344px;
        padding-right: 24px;
        height: 100%;

        &.ui-sortable-helper .list {
            box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        }

        .list {
            max-height: 100%;
            background-color: #EEF0F2;
            color: #000;
            border-radius: 2px;
            transition: box-shadow 150ms ease;

            .list-header {
                height: 84px;
                min-height: 64px;
                padding: 0 0 0 16px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);

                .list-header-name {
                    max-width: 80%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-size: 14px;
                    font-weight: 500;
                }

                .list-header-option-button {

                    .md-button {
                    }
                }
            }

            .list-content {
                position: relative;
                overflow: hidden;
                overflow-y: auto;
                min-height: 0;

                .list-cards {
                    position: relative;
                    min-height: 32px;
                    padding: 0 16px;

                    .card-sortable-placeholder {
                        background: rgba(0, 0, 0, 0.06);
                    }

                    > div {
                        transition: none !important;
                    }
                }
            }

            .list-footer {
                display: flex;
                flex-direction: column;
                flex: 1 0 auto;
                min-height: 48px;

                //add-topic-part-card {

                    .add-topic-part-card-button {
                        position: relative;
                        height: 48px;
                        min-height: 48px;
                        padding: 0 16px;
                        text-align: left;
                        text-transform: none;
                        font-weight: 500;
                        font-size: 14px;
                        background-color: #DCDFE2;
                        cursor: pointer;
                        border-radius: 2px;

                        i {
                            margin-right: 8px;
                            color: rgba(0, 0, 0, 0.6);
                        }
                    }

                    .add-topic-part-card-form-wrapper {
                        background-color: #DCDFE2;

                        .add-topic-part-card-form {
                            z-index: 999;
                            background: white;
                            display: block;
                            position: relative;
                            padding: 8px;
                            border-top: 1px solid rgba(0, 0, 0, 0.12);

                            md-input-container {
                                width: 100%;
                                margin: 0;
                                padding: 12px 8px;
                            }
                        }
                    }
               // }
            }

        }
    }

    .list-sortable-placeholder {
        background: rgba(0, 0, 0, 0.06);
        margin-right: 24px;
    }

    .new-list-wrapper {
        width: 344px;
        min-width: 344px;
        max-width: 344px;
        padding-right: 24px;

        .new-list {
            border-radius: 2px;
            background-color: #EEF0F2;

            .editable-wrap {
                display: flex;
                align-items: center;
                height: 64px;
                padding: 0 4px 0 12px;

                .editable-controls {
                    width: 100%;

                    .editable-input {
                        flex: 1;
                    }
                }
            }

            .new-list-form-button {
                text-transform: none;
                font-size: 15px;
                height: 64px;
                padding: 0 16px;
                margin: 0;
                width: 100%;

                i {
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    line-height: 40px;
                    margin-right: 16px;
                }
            }
        }
    }

    .list-card {
        @include list-card;
    }
}